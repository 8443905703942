<template>
    <BT-Blade-Item
        bladeName="product-category"
        :bladesData="bladesData"
        navigation="product-categories"
        title="Product Category">
        <template v-slot="{ item, data }">
            <v-container>
                <v-row>
                    <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-center">
                        <BT-Image-Edit
                            :height="120"
                            :id="item.id"
                            justify-center align-center
                            navigation="product-category-image"
                            :src="productLogoURL(item.id) + '?' + cacheData"
                            @uploaded="cacheData = new Date().getTime()"
                            :width="120" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <BT-Field-String
                            v-model="item.categoryName"
                            label="Category Name"
                            :isEditing="data.isEditing || data.isNew" />

                        <BT-Field-String
                            v-model="item.categoryDescription"
                            label="Category Description"
                            :isEditing="data.isEditing || data.isNew" />
                    </v-col>
                    <v-col cols="12">
                        <BT-Sidebar-List
                            v-if="!data.isNew"
                            inline
                            label="Products"
                            manyNavigation="product-ordering"
                            filterProp="CategoryID"
                            :singleID="item.id"
                            :itemProperties="['ID','ProductName','CategoryID']"
                            singleProp="categoryID"
                            manyText="productName"
                            :searchProps="['productName', 'abbreviation']"
                            small />
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Product-Category',
    components: {
        BTImageEdit: () => import('~components/BT-Image-Edit.vue'),
        BTSidebarList: () => import('~components/BT-Sidebar-List.vue')
    },
    data: function() {
        return {
            cacheData: new Date().getTime()
        }
    },
    props: {
        bladesData: null
    }
}
</script>